@font-face {
  font-family:"Thaleah";
  src: url("/src/assets/ThaleahFat.ttf") format("truetype");
}

:root {
  --mobileWidth: 90%;
  --tabletWidth: 70%;
  --laptopWidth: 60%;
  --mobilePixels: 600px;
  --tabletPixels: 900px;
  --laptopPixels: 900px;
  --primary-color: black;
  --secondary-color: #222222;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  color: white;
}
::-webkit-scrollbar {
  display: none;
}
h1 {
  font-size: 5rem;
  font-family: 'Thaleah', sans-serif;
}
h2 {
  font-size: 14rem;
  font-weight: 600;
  font-family: 'Thaleah', sans-serif;
}
h3 {
  font-size: 4rem;
  font-family: 'Thaleah', sans-serif;
  letter-spacing: 2px;
  font-weight: lighter;
}
h4,
h5 {
  font-family: 'Thaleah', sans-serif;
  letter-spacing: 2px;
}
h4{
  font-size: 3rem;
}
h5{
  font-size: 2rem;
  font-weight: lighter;
}
p,
li,
select {
  font-size: 1.8rem;
}
a,
button,
input
 {
  font-size: 2rem;
}
button {
  border: none;
  cursor: pointer;
  outline: none;
  border: 2px solid transparent;
  color: var(--primary-color);
  background-color: white;
  transition: all .3s ease-in-out;
}
button:hover {
  color: white;
  border: 2px solid white;
  background-color: transparent;
}
a{
  color: white;
  text-decoration: none;
  cursor: pointer;
}
select {
  height: 40px;
}
/* li {
  list-style: none;
  cursor: pointer;
} */
@media (max-width: 900px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 5rem;
    font-weight: 600;
  }
  h4{
    font-size: 2.2rem;
  }
  p,
  li {
    font-size: 1.4rem;
    font-weight: 200;
  }
  a,
  button,
  input,
  h5,
  select {
    font-size: 1.6rem;
  }
}